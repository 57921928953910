export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["salut i18n !!"])},
  "slide_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magazin de consignație pentru articole de design. Doar articole originale din segmentul Middle, Premium și Lux"])},
  "slide_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noi sau ca noi. Mărci de lux cu reduceri de până la 90%. "])},
  "slide_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La noi vă puteți elibera și completa garderoba la un preț accesibil"])},
  "slide_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecția sortimentului de către autor. Controlul profesional al autenticități."])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
  "about_resale_studio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["despre deservirea în studioul de revânzare (resale)"])},
  "when_nowhere_hang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Când nu ai loc unde să le pui și nimic ce să îmbraci"])},
  "rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reguli"])},
  "of_admission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de acceptare a produselor spre vânzare"])},
  "when_clothes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Când articolele au epuizat creditul dvs. de încântare"])},
  "founder_name_br": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evghenia <br/> Gruzdeva"])},
  "founder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evghenia Gruzdeva"])},
  "founder_and_permanent_br": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fondator și Director <br/> al Holly Chic Studio "])},
  "founder_and_permanent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fondator și director al Holy Chic Studio "])},
  "title_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cum a început totul:"])},
  "article1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cu o săptămână înainte de a naște, plecând într-un concediu de maternitate de scurtă durată, am decis să testez o idee care mă urmărea de mult timp. Biroul meu, care rămânea gol o vreme, a devenit un teren de testare. Etajul patru fără lift și parcare, 16 metri pătrați, două suporturi de umerașe și un dulap cu pantofi - iată cu ce s-a început Holy Chic."])},
  "article2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ideea a fost inspirată din propriile mele nevoi, dar nu pot spune că sunt autorul acesteia. În America și Europa serviciul de revânzare a articolelor personale de garderobă există deja de câteva decenii, însă aici în Moldova nimic asemanator în afară de anunțuri pe “trei de nouă” nu am avut."])},
  "article3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["În 2018, nimeni nu a auzit de noțiunea de ,,resale”- am fost nevoiți să formăm de la zero piața și atitudinea față de acest serviciu."])},
  "article4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pe lângă provocările legate de stereotipuri, am fost nevoiți să găsim echilibrul dintre puterea de cumpărare și prețul real al produselor care ne-au fost aduse spre revânzare. Dar cel mai dificil lucru a fost să construim o rețea de furnizori de încredere. Garderoba femeilor este un spațiu pur personal, străinii nu au voie acolo, din acest motiv trebuie mai întâi să îi câștigi încrederea. Vorba de la om la om și-a făcut treaba! Oamenii au început să ne recomande."])},
  "article5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La doar câteva luni mai târziu, am fost deja nevoiți să ne mutăm într-un sediu mai mare: cererea a depășit așteptările, iar recenziile ne-au inspirat atît de mult, feedback-ul a fost atât de sincer, încât mi-am dat seama că trebuie să creștem."])},
  "article6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La moment ocupăm 220 m2 în centrul orașului, iar doar în ultimul an, cifra de afaceri s-a triplat. Proiectul nu a atras niciodată investiții de la părți terțe, nu a primit granturi, nu a luat împrumuturi, iar pe toată perioada de existență a acestuia, nu a fost o singură lună neprofitabilă."])},
  "article7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amprentă de carbon - zero"])},
  "article8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Astăzi de nu mai putem ignora problemele legate de mediu și de consumul etic. Acestea au început să mă preocupe din ce în ce mai mult, mai ales după nașterea copilului, când involuntar începi să te întrebi: “Ce am făcut pentru a mă asigura că lumea în care trăiește copilul meu este una mai bună?” Prefer să încep mereu cu „propria mea grădină” și, în fiecare zi, afacerea mea contribuie, chiar și cu un mic pas, la lupta împotriva supraproducției."])},
  "article9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suntem angajați în cea mai ecologică direcție din industria modei: nu producem nimic și nu cumpărăm nimic, astfel nu avem deșeuri sau stocuri nevândute. Propunem reutilizarea produselor și punem accent pe articole de calitate care vor bucura mult timp noii lor proprietari."])},
  "article10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pe toată perioada de existență a Holy Chic, am dat ,,a doua viață’’ pentru 4.000 de rochii, 7.500 de perechi de pantofi și 3.400 de genți. În ceea ce privește resursele economisite, acestea sunt milioane de tone de apă, kilojouli de energie și litri de combustibil. Bineînțeles, așa statistici ne bucură imens, dar nu aș reduce esența serviciului nostru doar la ecologie..."])},
  "article11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klondike pentru fashionistele înflăcărate"])},
  "article12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Într-o piață unde brandurile de lux sunt puține, Holy Chic reprezintă o adevărată gură de aer proaspăt pentru femeile pasionate de articolele exclusive de designer. La noi găsești colecții de arhivă ale brandurilor legendare precum Celine, Maison Margiela, Dior și Chanel, rare eșarfe Hermes sau sandale ultra trendy ale sezonului curent la prețuri considerabil reduse. Dar ar trebui să ne iei în seamă nu doar când ești în căutarea mărcilor de lux și a rarităților, ci și în cazul articolelor de calitate din segmentul middle-premium care sunt expuse cu mândrie în studioul nostru. "])},
  "article13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pentru cei care înțeleg vintage-ul și colaborările, magazinul nostru este un adevărat cufăr de comori."])},
  "article14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Furnizorii noștri de încredere, care sunt cu noi de mult timp, știu deja ce preț pot obține la revânzare atunci când cumpără un articol nou. Ei tratează articolele cu grijă și, după ce le-au purtat cu plăcere, le dau mai departe. Acest comportament deja reflectă o conștientizare a importanței reutilizării. Simțim că începem să trăim o adevărată schimbare de paradigmă în ceea ce privește modul în care facem cumpărături."])},
  "article15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Despre miracole"])},
  "article16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dacă ați ști ce minuni se întâmplă la Holy Chic! îmbrăcat Câți absolvenți au fost îmbrăcați în Dior, câte genți magnifice au redevenit obiecte de mândrie și admirație! Ar fi trebuit să vedeți ochii unei femei care își cumpără pantofii visurilor ei - Manolo Blahnik sau Chanel, exact ca cei ai lui Carrie Bradshaw! Aceste branduri cu prețuri prohibitive, sunt inaccesibile pentru majoritatea oamenilor din clasa de mijloc. Iar unele femei care le-au cumpărat, pur și simplu nu găsesc mărimea potrivită… Ca rezultat, munca celor mai talentați designeri, meșteri și chiar specialiști în marketing, riscă să rămână pe rafturile de sus ale dulapurilor."])},
  "article_final": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dacă întâmpinați aceleași probleme ca sute dintre furnizorii și clienții noștri,suntem aici ca să vă ajutăm. Toate detaliile serviciilor noastre sunt disponibile pe site-ul nostru www.holychic.club. De asemenea, vă recomandăm să vă abonați la Instagramul nostru holy_chic.md și să vă alăturați deveniți parte din comunitateanoastră de iubitori de modă și articole exclusive. Vă încurajăm să investiți în călătorii, educație, familieși tot ce Vă face fericiți! Iar dacă hainele nu vă plac, le puteți schimba -  la noi este simplu și fascinant!"])},
  "how_resale_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Despre cum revânzarea va salva lumea "])},
  "brands_authentic_br": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Mărci <br/> și autenticitate"])},
  "brands_authentic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mărci și autenticitate"])},
  "take_dont_take": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ce acceptăm, ce nu acceptăm, cum verificăm"])},
  "wish_black_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wish list, black list și lupta împotriva produselor contrafăcute"])},
  "formula_br": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formula <br/> de calcul "])},
  "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formula de calcul "])},
  "final_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a plății finale către furnizor"])},
  "selling_price_commission_taxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prețul de vânzare, comision și impozite"])},
  "find_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cum ne găsiți"])},
  "address_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or. Chișinău, str. M.G. Bănulescu-Bodoni, 27 (intrarea din str. București)"])},
  "schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program de lucru"])},
  "schedule_values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zilnic, de la 10.00  pănă la 20.00"])},
  "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacte"])},
  "about_par_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holy Chic Studio este primul proiect de revânzare și consignație de autor, care operează cu succes pe piața din Chișinău de mai bine de 5 ani și rămâne liderul acestei industrii în prezent."])},
  "about_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviciul nostru vă permite să cumpărați și/sau să vindeți articole de design vestimentar pentru femei "])},
  "about_par_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate activitățile studioului nostru se bazează pe o dragoste inepuizabilă pentru lucrurile frumoase, consumul conștient și dorința de a soluționa vechea problemă a femeilor: „Când nu ai loc unde să le pui și nimic ce să îmbraci”"])},
  "about_par_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suntem o platformă promovată cu un magazin confortabil în centrul orașului și un număr mare de clienți fideli. "])},
  "about_par_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bine ați venit în lumea resale!"])},
  "familiarize_rules_br": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faceți cunoștință <br/> cu regulile"])},
  "go_back_br": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Înapoi"])},
  "rules_subtitle_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Așadar, ați decis să vindeți articolele care au epuizat creditul dvs. personal de încântare."])},
  "rules_par_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Înainte de a le oferi spre revânzare, vă sfătuim să treceți fiecare articol prin următoarele filtre:"])},
  "rules_subtitle_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gradul de uzare:"])},
  "rules_par_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferăm articolele noi sau aproape noi. Articolele cu defecte vizibile nu sunt acceptate pentru revânzare. "])},
  "rules_subtitle_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualitatea <br/> modelului:"])},
  "rules_par_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Studioul nostru de revânzare (resale) este foarte scrupulos în ceea ce privește sortimentul. Regula cheie pe care vă recomandăm să o respectați este - Nu încercați să vindeți ceea ce dvs. nu ați dori să cumpărați. "])},
  "rules_subtitle_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marca:"])},
  "rules_par_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un articol de designer de calitate poate fi deținut de mai mulți proprietari și își poate păstra aspectul original dacă este utilizat și îngrijit corespunzător. Anume din acest motiv acordăm preferință segmentelor Middle-Premium, Premium și Lux"])},
  "rules_subtitle_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprobarea preliminară:"])},
  "rules_par_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["După ce ați selectat produsele, vă rugăm să faceți poze clare cu fiecare articol propus spre vânzare. Fotografia trebuie să includă produsul în întregime, precum și marca/brandul. Aceste imagini vor fi suficiente pentru noi."])},
  "rules_par_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vă rugăm să ne transmiteți pozele în direct. "])},
  "rules_par_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["După procesul de aprobare preliminară, vă vom comunica articolele pe care le vom accepta spre vânzare și veți primi oferta de preț recomandat."])},
  "rules_par_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prin semnarea contractului, sunteți de acord ca bunurile dumneavoastră să fie fotografiate și filmate pentru a atrage potențiali cumpărători."])},
  "rules_conditions_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procesul de consignație:"])},
  "rules_conditions_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holy Chic Resale Studio acceptă articole pentru consignație pe o perioadă de două (2) luni. Prețul de vânzare este stabilit, confirmat și fixat într-un acord scris între Holy Chic și Dumneavoastră."])},
  "rules_conditions_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experții noștri evaluează produsele în funcție de următoarele 7 criterii:"])},
  "rules_conditions_sub_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relevanța produsului"])},
  "rules_conditions_sub_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starea produsului"])},
  "rules_conditions_sub_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prețul inițial al produsului la lansare"])},
  "rules_conditions_sub_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prețul produsului în perioada de reduceri (dacă este cazul)"])},
  "rules_conditions_sub_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compararea prețurilor pe piețele de revânzare din UE și SUA"])},
  "rules_conditions_sub_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prețul produsului prin intermediul buyerilor privați profesioniști (dacă este cazul)"])},
  "rules_conditions_sub_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colaborările speciale/rare și/sau statutul vintage al produsului."])},
  "rules_conditions_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne rezervăm dreptul să contactăm un serviciu independent de autentificare în cazul anumitor articole. Dacă alegem să facem acest lucru, managerul Holy Chic vă va contacta pentru aprobarea acestui serviciu. Dacă produsul se dovedește autentic, costul autentificării va fi suportat de compania Holy Chic. Dacă produsul nu se dovedește autentic, costul acestui proces va fi responsabilitatea dumneavoastră. personală. Vi se va cere să ridicați articolele și să acoperiți costul autentificării."])},
  "rules_conditions_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important:"])},
  "rules_conditions_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne rezervăm dreptul de a înregistra și de a publica (selectiv) articolele dumneavoastră. pe rețelele noastre de socializare pentru a promova vânzarea acestora."])},
  "rules_conditions_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plata pentru produsele vândute se va efectua în numerar la studioul nostru, iar eliberarea fondurilor este întotdeauna confirmată prin semnătura dumneavoastră."])},
  "rules_conditions_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["În cazul în care nu reușim să vindem produsele dumneavoastră. într-o perioadă de 2 luni, vă vom contacta pentru a sugera o reducere de 20% față de prețul de vânzare convenit și pentru a oferi produselor dumneavoastră o altă șansă la o nouă viață."])},
  "rules_conditions_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Returnarea produselor în consignație:"])},
  "rules_conditions_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dacă nu reușim să vindem produsele dumneavoastră timp de-o lună după aplicarea discount-ului sugerat, vom efectua o evaluare finală și vom organiza returnarea produselor către dumneavoastră. În acest moment, veți fi notificat prin mijloacele de contact alese de dumneavoastră, cum ar fi telefon, SMS sau e-mail."])},
  "rules_conditions_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["În plus, Holy Chic vă poate oferi opțiunea de a returna produsele dumneavoastră prin serviciul nostru de curierat, gratuit. Deoarece facilitățile noastre de depozitare nu sunt destinate stocării pe termen lung, vă rugăm să nu amânați ridicarea articolelor dumneavoastră și/sau să aprobați returnarea acestora de către noi, în cel mai scurt timp după ce ați fost notificat că produsele se întorc la dumneavoastră nevândute."])},
  "rules_conditions_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Managerul nostru de servicii pentru clienți vă va contacta pentru a coordona returnarea produselor într-un interval de trei săptămâni. Dacă nu primim un răspuns din partea dumneavoastră sau nu reușiți să ridicați produsele dumneavoastră în termenul indicat, ne rezervăm dreptul de a returna articolele dumneavoastră prin serviciul de curierat la adresa menționată în contract. Costul acestui serviciu va fi suportat de compania noastră."])},
  "rules_conditions_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vă mulțumim pentru abordarea conștientă și responsabilă a garderobei dumneavoastră și pentru considerarea față de Planeta noastră."])},
  "send_photos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRIMITEȚI <br/> POZA CU PRODUSUL <br/> PENTRU APROBARE"])},
  "from_final_cost_br": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["din costul final al produsului <br/> constituie comisionul magazinului"])},
  "income_tax_br": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["va fi impozitul pe venit*, <br/> care va fi reținut  din soldul <br/> sumei după deducerea comisioanelor"])},
  "income_tax_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Impozitul pe venit. Articolul 90-1. Reținerea finală a impozitului din unele tipuri de venit. Fiecare comisionar reține un impozit în mărime de 12% din plățile efectuate în folosul persoanei fizice."])},
  "work_brands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mărcile <br/> cu care lucrăm"])},
  "founder_par_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Când am deschis Holy Chic acum trei ani, nici nu îmi puteam imagina cât de mult îl așteptau!"])},
  "founder_par_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formatul de testare al proiectului se afla în vechiul meu birou pe 12 metri pătrați! "])},
  "founder_par_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Am instalat două suporturi cu haine și am confecționat un dulap pentru încălțăminte. După câteva luni, a trebuit să ne mutăm într-o încăpere mai mare. Cererea a fost uriașă, recenziile au fost foarte inspiratoare, feedback-ul a fost atât de încărcat și sincer încât mi-am dat seama că trebuie să ne extindem. "])},
  "wishlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wish list"])},
  "wishlist_par_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acordăm preferință segmentelor Middle Up, Premium și Lux. Lista mărcilor de mai jos vă va fi de ajutor. "])},
  "ban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HolyChic <br/> interzice vânzarea <br/> oricărui fel de copii <br/> și replici "])},
  "wishlist_par_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticitatea tuturor produselor este verificată de o echipă de autentificatori profesioniști. De asemenea, suntem singurii din țară care deținem dispozitivul de autentificare Entrupy. "])},
  "blacklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Black list"])},
  "ban_par": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu lucrăm cu produse din categoria mass-market, precum și cu hand-made sau mărci necunoscute. Nu ar trebui să ne oferiți mărci precum:"])},
  "etc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" etc."])}
}